import React from 'react'
import Form from '../../AppForm/AppForm'
import Navbar from '../../components/Navbar/Navbar'

const ApplicationForm = () => {
  return (
    <>
   <Navbar/>
   <Form/>  
    </>
  )
}

export default ApplicationForm;
